:root {
  --radius: 16;
  --border: 1;
  --blur: 10;
  --alpha: 0;
  --x: 0,
  --y: 0,
}

*, *::before, *::after {
  box-sizing: border-box;
}


.quest-card-gradient:hover {
  background: radial-gradient(ellipse 60% 80% at top, rgba(255,255,255, 0.03), transparent 100%);
}

.gradient-border {
  border-radius: calc(var(--radius) * 1px);
  border: 0;
  translate: calc(var(--x) * 1%) calc(var(--y) * 1%);
  transition: background 0.2s;
}

.gradient-border::after {
  --bg-size: calc(100% + (2px * var(--border)));
  content: "";
  pointer-events: none;
  position: absolute;
  inset: 0;
  background: radial-gradient(ellipse 70% 20% at top, rgba(var(--module-color), 0.3) 0%, var(--color-border-component-tertiary-default) 100%) center center / var(--bg-size) var(--bg-size);
  border-radius: calc(var(--radius) * 1px);
  border: calc(var(--border) * 1px) solid transparent;
  mask: linear-gradient(hsl(0 0% 100% / var(--alpha)), hsl(0 0% 100% / var(--alpha))), linear-gradient(hsl(0 0% 100%), hsl(0 0% 100%));
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  transition: background 0.2s;
}

[data-disabled=false] .gradient-border:hover::after {
  --bg-size: calc(100% + (2px * var(--border)));
  background: radial-gradient(ellipse 90% 20% at top, rgba(var(--module-color), 0.5) 0%, var(--color-border-component-tertiary-default) 100%) center center / var(--bg-size) var(--bg-size);
}


.gradient-border::after {
  transition: transform 0.5s 1s;
}
